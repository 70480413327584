<template>
  <div>

<!--    <b-row >-->

<!--      <b-col-->
<!--          lg="4"-->
<!--          sm="6"-->
<!--      >-->
<!--        <statistic-card-horizontal-->
<!--            icon="DollarSignIcon"-->
<!--            :statistic="'৳ '+currentBalance"-->
<!--            statistic-title="Current Balance For Withdrawal"-->
<!--        />-->
<!--      </b-col>-->

<!--    </b-row>-->
    <b-row>
      <b-card title="Withdrawal Log">

        <!-- search input -->
        <div class="custom-search d-flex justify-content-between">

          <b-form-group class="justify-content-start">
            <div class="d-flex align-items-center">
              <b-col md="12">
                <v-select
                    id="fh-select"
                    v-model="user_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="affiliateSimple"
                    :reduce="name=>name.user_id"
                    label="name"
                    @input="updateProduct"
                    class="mr-50 "
                    style="min-width:300px"
                />
              </b-col>
              <b-col>
                <b-button @click="resetTable">
                  Reset
                </b-button>

              </b-col>


            </div>
          </b-form-group>
          <b-form-group class="justify-content-end">
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                  v-model="searchTerm"
                  placeholder="Search"
                  type="text"
                  class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table
            :columns="columns"
            :rows="rows"
            :rtl="direction"
            :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
            :pagination-options="{
        enabled: true,
        perPage:pageLength

      }"
            styleClass="vgt-table striped condensed no-outline"
        >

          <template
              slot="table-row"
              slot-scope="props"
          >

            <!-- Column: Name -->


            <!-- Column: Action -->

            <span v-if="props.column.field === 'created_at'">
           <span>{{props.row.created_at.split('T')[0]}}</span><br>
          <small class="font-weight-bolder">{{props.row.created_at.split('T')[1].split("+")[0].split(".")[0]}}</small>


        </span>
            <span v-else-if="props.column.field === 'index'">
         <span>{{ (props.row.originalIndex+1)+(pageNumber>1?((pageNumber-1)*10):0)}}</span>
        </span>
            <span v-else-if="props.column.field === 'payout_details'">
         <small class="font-weight-bolder">{{ props.row.payout_details}}</small>
        </span>
            <span v-else-if="props.column.field === 'req_status'" >
               <span class="text-nowrap"
                     v-b-modal.modal-prevent-closing

                     @click="updateOrder(props.row)">
         <span v-if="props.row.req_status==='Pending'">
              <b-badge pill variant="light-primary">{{ props.row.req_status }}</b-badge>
            </span>
          <span v-else-if="props.row.req_status==='Approved'">
              <b-badge pill variant="light-success">{{ props.row.req_status }}</b-badge>
            </span>
            <span v-else>
              <b-badge pill variant="light-danger">{{ props.row.req_status }}</b-badge>
            </span>
        </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
          </template>

          <!-- pagination -->
          <template
              slot="pagination-bottom"
              slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <!--            <span class="text-nowrap ">-->
                <!--              Showing 1 to-->
                <!--            </span>-->
                <!--            <b-form-select-->
                <!--                v-model="pageLength"-->
                <!--                :options="['3','5','10']"-->
                <!--                class="mx-1"-->
                <!--                @input="(value)=>props.perPageChanged({currentPerPage:value})"-->
                <!--            />-->
                <span class="text-nowrap"> Total {{totalCount }} entries </span>
              </div>
              <div>
                <b-pagination
                    :value="1"
                    :total-rows="totalCount"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @change="handleChangePage"
                >
                  <template #prev-text>
                    <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>


        <b-modal
            id="modal-prevent-closing"
            ref="my-modal"
            centered
            :title="getModalTitle()"
            ok-title="Submit"
            cancel-variant="outline-secondary"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
        >
          <form
              ref="form"
              @submit.stop.prevent="handleSubmit"
          >
            <b-form-group label="Select current status">
              <b-form-radio-group
                  v-model="modify.status"
                  :options="options"
                  name="radios-stacked"
                  stacked
              />
            </b-form-group>
          </form>
        </b-modal>

      </b-card>
    </b-row>
  </div>


</template>

<script>

import {
  BAvatar,
  BButton,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BCard,
  BModal,
  BFormTextarea,
  VBModal,
  BFormRadioGroup,
  BCardText
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import vSelect from "vue-select"
import Ripple from "vue-ripple-directive";
import {mapState} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BCard,
    BBadge,
    BButton,
    BPagination,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
    BCardText,
    vSelect,
    StatisticCardHorizontal,
    BFormRadioGroup,

  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      nameState: null,
      addNewMode:false,
      popupActive:true,
      modify:{
        id:'',
        status:'',
      },
      item_id:null,
      user_id:null,
      pageNumber:1,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: '#',
          field: 'index',
          type:'number',
          tdClass: 'text-center',
          thClass:'text-center'
        },


        {
          label: 'Affiliate',
          field: 'affiliate',
          tdClass: 'text-start',
          thClass:'text-center'
        },
        {
          label: 'Payout Method',
          field: 'payout_method',
          tdClass: 'text-start',
          thClass:'text-center'
        },
        {
          label: 'Payout Details',
          field: 'payout_details',
          tdClass: 'text-start',
          thClass:'text-center'
        },
        {
          label: 'Requested Amount',
          field: 'req_amount',
          tdClass: 'text-center',
          thClass:'text-center',
          sortable:false
        },
        {
          label: 'Request Status',
          field: 'req_status',
          tdClass: 'text-center',
          thClass:'text-center',
          sortable:false
        },
        {
          label: 'Request Date',
          field: 'created_at',
          tdClass: 'text-center',
          thClass:'text-center'
        },
      ],
      options: [
        {text: 'Pending', value: 'Pending'},
        {text: 'Approved', value: 'Approved'},
        {text: 'Rejected', value: 'Rejected'},
      ],
      showAdd:false,
      showEdit:false,
      showDelete:false,
      rows: [],
      searchTerm: '',

    }
  },
  computed: {
    ...mapState('affiliate', ["affiliates", "affiliateSimple", "submitLoading", "submitError"]),

    ...mapState('affiliateFinanceWithdrawal', ["withdrawal","totalCount","withdrawalLoading", "withdrawalError","submitLoading","submitSuccess"]),
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true
        return this.dir
      }
      this.dir = false
      return this.dir
    },
  },
  watch: {
    withdrawal(newValue, oldValue) {
      if (newValue) {
        this.rows=this.withdrawal
      }
    }
  },
  created() {

    this.$store.dispatch('affiliate/fetchAffiliateList')
    this.$store.dispatch('affiliate/fetchAffiliateListSimple')
    this.user_id="all"
    this.$store.dispatch('affiliateFinanceWithdrawal/fetchWithdrawLog', {count:this.pageLength, page:1,user:this.user_id })
    this.rows = this.withdrawal
  },
  methods:{
    updateProduct() {
      console.log(this.user_id)
      this.$store.dispatch('affiliateFinanceWithdrawal/fetchWithdrawLog', {
        user: this.user_id,
        count: this.pageLength,
        page: 1
      })
    },
    resetTable() {
      this.user_id='all'
      console.log(this.user_id)
      this.$store.dispatch('affiliateFinanceWithdrawal/fetchWithdrawLog', {
        user: this.user_id,
        count: this.pageLength,
        page: 1
      })
    },
    handleChangePage(page) {

      this.pageNumber=page
      this.$store.dispatch('affiliateFinanceWithdrawal/fetchWithdrawLog', {count:this.pageLength, page:page, user:this.user_id})
    },
    handlePageChange(active) {
      this.log.push(`the user change page:  ${active}`)
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    resetModal() {
      this.nameState = null
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },
    async handleSubmit() {
      this.modify.user=this.user_id
      this.$store.dispatch('affiliateFinanceWithdrawal/updateWithdrawReq', this.modify).then(result => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: result.code === 400 ? `Error` : `Success`,
            icon: result.code === 400 ? `ThumbsDownIcon` : 'ThumbsUpIcon',
            variant: result.code === 400 ? `danger` : 'success',
            text: result.message,
          },
        })
      })

      this.$nextTick(() => {
        this.$refs['my-modal'].toggle('#toggle-btn')
      })
    },

    updateOrder(data) {
      this.addNewMode = false;

      this.modify.id = data.id
      this.modify.status = data.req_status;
      console.log(this.modify)
      this.popupActive=true;
    },

    getModalTitle() {

      return 'Update Withdrawal Request'
    }
  }
}
</script>


<style lang="scss" >
.no-outline{
  border:none !important;
}
.f20{
  font-size: 15px !important;
}
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

</style>
